<template>
    <div>
        <v-layout row wrap>
            <v-flex xs5>
                <v-text-field label="Name"
                              v-model="locationName"
                              :error-messages="locationNameErrors"
                              @input="$v.locationName.$touch()"
                              @blur="$v.locationName.$touch()"
                              @change="itemChanged()">
                </v-text-field>
            </v-flex>
            <v-flex xs5>
                <v-text-field label="Address"
                              v-model="locationAddress"
                              @change="itemChanged()">
                </v-text-field>
            </v-flex>
            <v-flex xs2 class="container">
                <v-btn icon @click.native="removeRow()" class="vertical-center">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-flex>

        </v-layout>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        props: ["id"],
        data() {
            return {
                locationName: '',
                locationAddress: ''
            }
        },
        computed: {
            locationNameErrors() {
                const errors = []
                if (!this.$v.locationName.$dirty) return errors
                !this.$v.locationName.required && errors.push('Location Name is required')
                return errors
            }
        },
        methods: {
            removeRow: function () {
                this.$emit('remove', this.id)
            },
            itemChanged: function () {
                this.$emit('updateItem', { id: this.id, locationName: this.locationName, locationAddress: this.locationAddress })
            },
            delayedTrigger: function () {
                setTimeout(this.triggerErrors, 5000)
            },
            triggerErrors: function () {
                this.$v.$touch(); //Triggers Error Messages
            }
        },
        beforeMount() {
            this.delayedTrigger()
        },
        validations: {
            locationName: {
                required
            }
        }

    }
</script>

<style scoped>
    .container {
        height: 70px;
        position: relative;
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
</style>